.map-wrap {
    width: 100vw;
    height: 100vh;
}

.bottom-right-controls {
    position: absolute;
    right: 3em;
    bottom: 3em;
    z-index: 15;
}

.top-right-controls {
    position: absolute;
    right: 3em;
    top: 3em;
    z-index: 15;
}

.top-left-controls {
    position: absolute;
    left: 3em;
    top: 3em;
    z-index: 15;
}

.app-brand {
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: auto;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-family: 'Squada One';
    color: #005FA5;
    font-size: 20pt;
    padding: 4px 10px 4px 4px;
}

.maplibregl-ctrl-bottom-right {
    margin-right: 3em;
    margin-bottom: calc(3em + 72px);
}

.maplibregl-ctrl-bottom-right .maplibregl-ctrl {
    border-radius: 50%;
    width: 56px;
    height: 56px;
}
.maplibregl-ctrl-bottom-right .maplibregl-ctrl-geolocate {
    width: 56px;
    height: 56px;
}

.mapboxgl-user-location-dot, .mapboxgl-user-location-dot:before {
    background-color: #00939A;
}

.maplibregl-marker:hover {
    z-index: 100;
}

.poi-marker {
    position: relative;
}

.poi-pin-marker {
    cursor: pointer;
    transition: transform 150ms ease-in-out;
    transform-origin: bottom center;

}

.poi-pin-marker:hover, .poi-marker.active .poi-pin-marker {
    transform: scale(1.36);
}

.poi-title-marker {
    position: absolute;
    top: 37px;
    left: 50%;
    width: auto;
    transform: translate(-50%, 0);
    text-align: center;
    visibility: hidden;
    opacity: 0;
    white-space: nowrap;
    transition: visibility 0s, opacity 0.3s linear;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 0 4px;
    border-radius: 5px;
}

.poi-marker.active .poi-title-marker, .poi-marker:hover .poi-title-marker {
    visibility: visible;
    opacity: 1;
}

.poi-info-card {
    position: absolute;
    z-index: 1000;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    -moz-transform: translate(-50%,0);
    -ms-transform: translate(-50%,0);
    -o-transform: translate(-50%,0);
    transform: translate(-50%,0);
    bottom: 1em;
    max-width: 640px;
    width: calc(100% - 2em);
}

.poi-description {
    font-size: 17px !important;
    max-height: 100px;
    overflow: hidden;
}

.poi-description:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 60px;
    left: 0;
    z-index: 10;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-linear-gradient(bottom, white, white 50%, rgba(255, 255, 255, 0));
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
}

.poi-description p:first-of-type {
    margin-top: 0;
}

.poi-info-dialog img, .poi-info-dialog iframe{
    max-width: 100%;
    height: inherit !important;
}