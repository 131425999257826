body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  color: #005FA5
}


@font-face {
  font-family: 'CartoWeb';
  src:  url('./assets/fonts/CartoWeb.eot?4aufy1');
  src:  url('./assets/fonts/CartoWeb.eot?4aufy1#iefix') format('embedded-opentype'),
  url('./assets/fonts/CartoWeb.ttf?4aufy1') format('truetype'),
  url('./assets/fonts/CartoWeb.woff?4aufy1') format('woff'),
  url('./assets/fonts/CartoWeb.svg?4aufy1#CartoWeb') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic_"], [class*=" ic_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'CartoWeb' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic_explorade_culture:before {
  content: "\e900";
}
.ic_explorade_eau:before {
  content: "\e901";
}
.ic_explorade_edifice:before {
  content: "\e902";
}
.ic_explorade_entreprise:before {
  content: "\e903";
}
.ic_explorade_equipement:before {
  content: "\e904";
}
.ic_explorade_institution:before {
  content: "\e905";
}
.ic_explorade_liaison:before {
  content: "\e906";
}
.ic_explorade_localite:before {
  content: "\e907";
}
.ic_explorade_navire:before {
  content: "\e908";
}
.ic_explorade_port:before {
  content: "\e909";
}
.ic_parcoursmer_boating:before {
  content: "\e90a";
}
.ic_parcoursmer_energy:before {
  content: "\e90b";
}
.ic_parcoursmer_fishing:before {
  content: "\e90c";
}
.ic_parcoursmer_marine:before {
  content: "\e90d";
}
.ic_parcoursmer_rd:before {
  content: "\e90e";
}
.ic_parcoursmer_repair:before {
  content: "\e90f";
}
.ic_parcoursmer_shipping:before {
  content: "\e910";
}

img.responsive-img {
  max-width: 100%;
}